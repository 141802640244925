import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { forkJoin, map, pipe } from 'rxjs';

export type FeatureState = { featureFlags: { [key: string]: boolean } };

const initialState: FeatureState = {
    featureFlags: {},
};

export const FeatureFlagsStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withDevtools('FeatureFlagsStore'),
    withComputed(({ featureFlags }) => ({
        showAnalysis: computed(() => featureFlags().analysis),
        showManageCrop: computed(() => featureFlags().manageCrop),
    })),
    withMethods((store) => {
        const initializeFeatureFlags = rxMethod(
            pipe(
                map(([analysis, manageCrop]) => {
                    forkJoin(analysis, manageCrop).subscribe(([analysis, manageCrop]) => {
                        const flags = { analysis: analysis, manageCrop: manageCrop };
                        patchState(store, (state) => ({
                            featureFlags: {
                                ...state.featureFlags,
                                ...flags,
                            },
                        }));
                    });
                }),
            ),
        );

        return {
            initializeFeatureFlags,
        };
    }),
);
