import dayjs, { Dayjs } from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import minMax from 'dayjs/plugin/minMax';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { LocalDateString } from './date-constants';

dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(isLeapYear);
dayjs.extend(minMax);

export const dateToLocalDateString = (date: Dayjs): string => {
    return date.format(LocalDateString);
};

export const dateToLocalWeek = (date: Dayjs): string => {
    return `${date.isoWeekYear()}-${date.isoWeek().toString().padStart(2, '0')}`;
};

/**
 * Helper to create a consistent week key from a date.
 * Using isoWeekYear and isoWeek ensures consistent keys.
 */
export const getYearWeekKey = (date: Dayjs): string => {
    return `${date.isoWeekYear()}-${date.isoWeek()}`;
};

/**
 * Helper to convert week string {YYYY-WW} to utc dayjs date.
 * Using utc, isoWeekYear, isoWeek, and startOf isoWeek ensures consistent keys.
 */
export const convertWeekToDate = (weekString: string): Dayjs => {
    const [yearStr, weekStr] = weekString.split('-');
    const year = parseInt(yearStr, 10);
    const week = parseInt(weekStr, 10);
    return dayjs.utc().year(year).week(week).startOf('isoWeek');
};

/**
 * Helper function to build array of week startDates between 2 dates.
 * Using isoWeek, and startOf isoWeek ensures each week date start on the same day.
 */
export const getAllIsoWeeksBetween = (start: Dayjs, end: Dayjs): Dayjs[] => {
    let current = start.startOf('isoWeek');
    const result: Dayjs[] = [];

    while (current.isSame(end, 'week') || current.isBefore(end, 'week')) {
        result.push(current);
        current = current.add(1, 'week');
    }

    return result;
};
