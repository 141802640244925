export { deepmerge, deepmergeCustom, deepmergeInto, deepmergeIntoCustom } from 'deepmerge-ts';
function validateCSSProperty(property, value, convertUnitlessToPixels = false) {
  value = value?.toString().trim();
  if (convertUnitlessToPixels && value) {
    // Convert unit-less value to pixels
    // e.g. 10 => 10px
    // NB. We only do this for backwards compatibility for number input properties
    if (/^\d+$/.test(value)) {
      value = `${value}px`;
    }
  }
  const supported = CSS.supports(property, value);
  if (!supported && value) {
    console.warn(`Property '${property}' does not support provided value: '${value}'.`);
  }
  return supported ? value : null;
}
function widthAttribute(value) {
  return validateCSSProperty('width', value, true);
}
function maxWidthAttribute(value) {
  return validateCSSProperty('max-width', value);
}
function heightAttribute(value) {
  return validateCSSProperty('height', value, true);
}
function paddingAttribute(value) {
  return validateCSSProperty('padding', value);
}
function colorAttribute(value) {
  return validateCSSProperty('color', value);
}

/** Mapping of digits to regex patterns matching original, superscript, and subscript digits */
const digitReplacements = {
  '0': '[0\u2070\u2080]',
  '1': '[1\u00b9\u2081]',
  '2': '[2\u00b2\u2082]',
  '3': '[3\u00b3\u2083]',
  '4': '[4\u2074\u2084]',
  '5': '[5\u2075\u2085]',
  '6': '[6\u2076\u2086]',
  '7': '[7\u2077\u2087]',
  '8': '[8\u2078\u2088]',
  '9': '[9\u2079\u2089]'
};
/**
 * Process an input string and replace standalone digits with regex patterns matching original, superscript, and subscript digits
 * @param text The input string to process
 * @returns The processed string with regex patterns for standalone digits
 */
function fuzzyMatch(text) {
  if (!text) return undefined;
  // Escape special regex characters
  const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  // Replace only standalone digits
  const standaloneDigitRegex = /(?<!\d)(?=\D*\d)(\d)(?!\d)/g;
  return escapedText.replace(standaloneDigitRegex, digit => digitReplacements[digit]);
}

/**
 * Generated bundle index. Do not edit.
 */

export { colorAttribute, fuzzyMatch, heightAttribute, maxWidthAttribute, paddingAttribute, widthAttribute };
