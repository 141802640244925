import { inject, Injectable } from '@angular/core';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { filter, map, Observable } from 'rxjs';

import { distinct } from '@priva/utilities/predicate';
import {
    PrivaVariableDataService,
    PrivaVariableRequestContainer,
    PrivaVariableResponse,
    ServiceType,
} from '@priva/variables';

import { roundToMetricDecimals } from '@app/metrics';

import { Kpi, KPIS_REQUEST_TYPE, KpisRequest } from './kpis.api.model';
import { KpisApiService } from './kpis.api.service';

dayjs.extend(isBetween);

@Injectable({
    providedIn: 'root',
})
export class KpisService implements PrivaVariableDataService {
    private readonly kpisApiService = inject(KpisApiService);
    public readonly source = KPIS_REQUEST_TYPE;
    public readonly type = ServiceType.Service;

    public getData(
        requestData: PrivaVariableRequestContainer<KpisRequest>[],
        _offsetInMinutes: number,
        start: Date,
        end: Date,
    ): Observable<PrivaVariableResponse> {
        const requests = requestData.map((requestContainer) => requestContainer.request);
        const kpiIdsForEachRequest = requests.flatMap(({ kpiId }) => kpiId).filter(distinct());
        if (kpiIdsForEachRequest.length !== 1) {
            return null;
        }

        const kpiIds = [kpiIdsForEachRequest[0]];
        const cropId = requestData[0].request.cropId;

        return this.kpisApiService.getKpisDate(dayjs(start), dayjs(end), kpiIds, cropId).pipe(
            filter(({ kpiValues }) => !!kpiValues?.length),
            map((response): PrivaVariableResponse => {
                let values = [];
                response.kpiValues.forEach((kpi: Kpi) => {
                    const kpiValues = this.getTargetValues(kpi);
                    values = values.concat(kpiValues);
                });

                return this.createKpi(requestData, values);
            }),
        );
    }

    public getTargetValues(kpi: Kpi) {
        if (!kpi.values || kpi.values.length === 0) {
            // Return an empty array if values are empty
            return [];
        }

        return kpi.values.map((value) => {
            const date = dayjs.utc(value.localDate).startOf('day');
            return {
                value: {
                    x: date,
                    y: roundToMetricDecimals(value.value, kpi.kpiId),
                },
                target: {
                    x: date,
                    y: value.target ? roundToMetricDecimals(value.target.value, kpi.kpiId) : null,
                },
                bandMin: {
                    x: date,
                    y: value.target ? roundToMetricDecimals(value.target.bandMinValue, kpi.kpiId) : null,
                },
                bandMax: {
                    x: date,
                    y: value.target ? roundToMetricDecimals(value.target.bandMaxValue, kpi.kpiId) : null,
                },
            };
        });
    }

    public createKpi(requestData: PrivaVariableRequestContainer<KpisRequest>[], values) {
        const responseData = requestData.map((request) => {
            const key = request.variableId.split('_')[0];
            const valuesArray = values.map((value) => value[key]);
            return {
                props: request,
                values: valuesArray,
            };
        });

        return {
            source: this.source,
            data: [...responseData],
        };
    }
}
