import * as MetricsConfigs from './metric-configs';

const getDecimalsByMetricId = (metricId: string): number | undefined => {
    const metricConfig = MetricConfigs.find(({ id }) => id === metricId);
    return metricConfig?.decimals;
};

// This function is used to round the value to the decimals set for the metricId
export const roundToMetricDecimals = (value: number, metricId: string): number => {
    if (!value || !metricId) return value;

    const decimals = getDecimalsByMetricId(metricId);

    if (decimals === undefined) return value;

    const factor = Math.pow(10, decimals);
    return Math.round(value * factor) / factor;
};

// All metrics
export const MetricConfigs = [
    MetricsConfigs.RadSum24hConfig,
    MetricsConfigs.RadSumOut24hConfig,
    MetricsConfigs.CumRadSumOutConfig,
    MetricsConfigs.WeekRadSumConfig,
    MetricsConfigs.Temp24hConfig,
    MetricsConfigs.TempDayConfig,
    MetricsConfigs.TempNightConfig,
    MetricsConfigs.RTRRadSumConfig,
    MetricsConfigs.RTRParConfig,
    MetricsConfigs.Co224hConfig,
    MetricsConfigs.Co2DayConfig,
    MetricsConfigs.RH24hConfig,
    MetricsConfigs.WaterUptakeConfig,
    MetricsConfigs.DoseConfig,
    MetricsConfigs.DrainConfig,
    MetricsConfigs.ECDrainConfig,
    MetricsConfigs.PhDrainConfig,
    MetricsConfigs.YieldConfig,
    MetricsConfigs.SettingSpeedConfig,
    MetricsConfigs.RipeningTimeConfig,
];

// Dashboard metrics
export const DashboardMetrics = [
    MetricsConfigs.CumRadSumOutConfig,
    MetricsConfigs.RadSumOut24hConfig,
    MetricsConfigs.Temp24hConfig,
    MetricsConfigs.Co2DayConfig,
    MetricsConfigs.RH24hConfig,
    MetricsConfigs.WaterUptakeConfig,
    MetricsConfigs.ECDrainConfig,
    MetricsConfigs.PhDrainConfig,
    MetricsConfigs.RTRRadSumConfig,
    MetricsConfigs.YieldConfig,
    MetricsConfigs.FruitWeightConfig,
    MetricsConfigs.RipeningTimeConfig,
    MetricsConfigs.FruitLoadConfig,
    MetricsConfigs.SettingSpeedConfig,
    MetricsConfigs.PlantGrowthConfig,
];

// KPI metrics
export const KpiGrowingConditionMetrics = [
    MetricsConfigs.RadSumOut24hConfig,
    MetricsConfigs.Temp24hConfig,
    MetricsConfigs.Co2DayConfig,
    MetricsConfigs.RH24hConfig,
    MetricsConfigs.WaterUptakeConfig,
    MetricsConfigs.ECDrainConfig,
    MetricsConfigs.PhDrainConfig,
    MetricsConfigs.RTRRadSumConfig,
];

export const KpiCropDevelopmentMetrics = [
    MetricsConfigs.PlantGrowthConfig,
    MetricsConfigs.StemDiameterConfig,
    MetricsConfigs.SettingSpeedConfig,
    MetricsConfigs.FruitLoadConfig,
    MetricsConfigs.RipeningTimeConfig,
];

export const KpiProductionMetrics = [MetricsConfigs.YieldConfig, MetricsConfigs.FruitWeightConfig];

export const KpiResourceConsumptionMetrics = [MetricsConfigs.GasConfig, MetricsConfigs.ElectricityConfig];

// Analysis tabs
export const WaterVsRadiation = [
    MetricsConfigs.WaterUptakeConfig,
    MetricsConfigs.RadSumOut24hConfig,
    MetricsConfigs.Temp24hConfig,
    MetricsConfigs.Co2DayConfig,
];

export const Climate = [
    MetricsConfigs.Temp24hConfig,
    MetricsConfigs.RH24hConfig,
    MetricsConfigs.Co2DayConfig,
    MetricsConfigs.RadSumOut24hConfig,
    MetricsConfigs.RTRRadSumConfig,
];

export const Irrigation = [
    MetricsConfigs.WaterUptakeConfig,
    MetricsConfigs.DoseConfig,
    MetricsConfigs.DrainConfig,
    MetricsConfigs.ECDrainConfig,
    MetricsConfigs.PhDrainConfig,
];

export const Temperatures = [
    MetricsConfigs.Temp24hConfig,
    MetricsConfigs.TempDayConfig,
    MetricsConfigs.TempNightConfig,
    MetricsConfigs.GasConfig,
    MetricsConfigs.ElectricityConfig,
];

export const CropDevelopment = [
    MetricsConfigs.YieldConfig,
    MetricsConfigs.RipeningTimeConfig,
    MetricsConfigs.SettingSpeedConfig,
    MetricsConfigs.FloweringSpeedConfig,
    MetricsConfigs.FlowersTrussConfig,
    MetricsConfigs.FruitTrussConfig,
    MetricsConfigs.FruitStemConfig,
    MetricsConfigs.FruitLoadConfig,
    MetricsConfigs.SetFruitPerM2Config,
    MetricsConfigs.SetFruitPerPlantConfig,
    MetricsConfigs.HarvestFruitConfig,
    MetricsConfigs.HarvestTrussConfig,
    MetricsConfigs.FruitWeightConfig,
    MetricsConfigs.PlantGrowthConfig,
    MetricsConfigs.FloweringHeightConfig,
    MetricsConfigs.InternodeLengthConfig,
    MetricsConfigs.StemDiameterConfig,
    MetricsConfigs.LeafCountConfig,
    MetricsConfigs.LeafLengthConfig,
    MetricsConfigs.LeafWidthConfig,
    MetricsConfigs.HeadDensityConfig,
    MetricsConfigs.RadSumOut24hConfig,
    MetricsConfigs.Temp24hConfig,
    MetricsConfigs.Co2DayConfig,
];

export const AnalysisMetrics = [
    MetricsConfigs.RadSum24hConfig,
    MetricsConfigs.RadSumOut24hConfig,
    MetricsConfigs.Temp24hConfig,
    MetricsConfigs.TempDayConfig,
    MetricsConfigs.TempNightConfig,
    MetricsConfigs.RTRRadSumConfig,
    MetricsConfigs.RTRParConfig,
    MetricsConfigs.Co224hConfig,
    MetricsConfigs.Co2DayConfig,
    MetricsConfigs.RH24hConfig,
    MetricsConfigs.WaterUptakeConfig,
    MetricsConfigs.DoseConfig,
    MetricsConfigs.DrainConfig,
    MetricsConfigs.ECDrainConfig,
    MetricsConfigs.PhDrainConfig,
    MetricsConfigs.YieldConfig,
    MetricsConfigs.RipeningTimeConfig,
    MetricsConfigs.SettingSpeedConfig,
    MetricsConfigs.FloweringSpeedConfig,
    MetricsConfigs.FlowersTrussConfig,
    MetricsConfigs.FruitTrussConfig,
    MetricsConfigs.FruitStemConfig,
    MetricsConfigs.FruitLoadConfig,
    MetricsConfigs.SetFruitPerM2Config,
    MetricsConfigs.SetFruitPerPlantConfig,
    MetricsConfigs.HarvestFruitConfig,
    MetricsConfigs.HarvestTrussConfig,
    MetricsConfigs.FruitWeightConfig,
    MetricsConfigs.PlantGrowthConfig,
    MetricsConfigs.FloweringHeightConfig,
    MetricsConfigs.InternodeLengthConfig,
    MetricsConfigs.StemDiameterConfig,
    MetricsConfigs.LeafCountConfig,
    MetricsConfigs.LeafLengthConfig,
    MetricsConfigs.LeafWidthConfig,
    MetricsConfigs.HeadDensityConfig,
    MetricsConfigs.GasConfig,
    MetricsConfigs.ElectricityConfig,
];

export const AnomalyMetrics = [
    MetricsConfigs.Temp24hConfig,
    MetricsConfigs.TempNightConfig,
    MetricsConfigs.TempDayConfig,
    MetricsConfigs.Co224hConfig,
    MetricsConfigs.Co2DayConfig,
    MetricsConfigs.RH24hConfig,
];

// Characteristic metrics
export const CharacteristicsMetrics = [
    MetricsConfigs.YieldConfig,
    MetricsConfigs.RipeningTimeConfig,
    MetricsConfigs.SettingSpeedConfig,
    MetricsConfigs.FloweringSpeedConfig,
    MetricsConfigs.FlowersTrussConfig,
    MetricsConfigs.FruitTrussConfig,
    MetricsConfigs.FruitStemConfig,
    MetricsConfigs.FruitLoadConfig,
    MetricsConfigs.SetFruitPerM2Config,
    MetricsConfigs.SetFruitPerPlantConfig,
    MetricsConfigs.HarvestFruitConfig,
    MetricsConfigs.HarvestTrussConfig,
    MetricsConfigs.FruitWeightConfig,
    MetricsConfigs.PlantGrowthConfig,
    MetricsConfigs.FloweringHeightConfig,
    MetricsConfigs.InternodeLengthConfig,
    MetricsConfigs.StemDiameterConfig,
    MetricsConfigs.LeafCountConfig,
    MetricsConfigs.LeafLengthConfig,
    MetricsConfigs.LeafWidthConfig,
    MetricsConfigs.HeadDensityConfig,
];

export const CropPlanMetrics = [
    MetricsConfigs.RadSumOut24hConfig,
    MetricsConfigs.CumRadSumOutConfig,
    MetricsConfigs.Temp24hConfig,
    MetricsConfigs.Co2DayConfig,
    MetricsConfigs.RH24hConfig,
    MetricsConfigs.WaterUptakeConfig,
    MetricsConfigs.ECDrainConfig,
    MetricsConfigs.PhDrainConfig,
    MetricsConfigs.RTRRadSumConfig,
    MetricsConfigs.GasConfig,
    MetricsConfigs.ElectricityConfig,
    MetricsConfigs.YieldConfig,
    MetricsConfigs.RipeningTimeConfig,
    MetricsConfigs.SettingSpeedConfig,
    MetricsConfigs.FloweringSpeedConfig,
    MetricsConfigs.FlowersTrussConfig,
    MetricsConfigs.FruitTrussConfig,
    MetricsConfigs.FruitStemConfig,
    MetricsConfigs.FruitLoadConfig,
    MetricsConfigs.SetFruitPerM2Config,
    MetricsConfigs.SetFruitPerPlantConfig,
    MetricsConfigs.PlantGrowthConfig,
    MetricsConfigs.FloweringHeightConfig,
    MetricsConfigs.InternodeLengthConfig,
    MetricsConfigs.StemDiameterConfig,
    MetricsConfigs.LeafCountConfig,
    MetricsConfigs.LeafLengthConfig,
    MetricsConfigs.LeafWidthConfig,
    MetricsConfigs.HeadDensityConfig,
    MetricsConfigs.HarvestFruitConfig,
    MetricsConfigs.HarvestTrussConfig,
    MetricsConfigs.FruitWeightConfig,
];

export const AllMetricConfigs = [
    MetricsConfigs.RadSum24hConfig,
    MetricsConfigs.RadSumOut24hConfig,
    MetricsConfigs.CumRadSumOutConfig,
    MetricsConfigs.WeekRadSumConfig,
    MetricsConfigs.Temp24hConfig,
    MetricsConfigs.TempDayConfig,
    MetricsConfigs.TempNightConfig,
    MetricsConfigs.RTRRadSumConfig,
    MetricsConfigs.RTRParConfig,
    MetricsConfigs.Co224hConfig,
    MetricsConfigs.Co2DayConfig,
    MetricsConfigs.RH24hConfig,
    MetricsConfigs.WaterUptakeConfig,
    MetricsConfigs.DoseConfig,
    MetricsConfigs.DrainConfig,
    MetricsConfigs.ECDrainConfig,
    MetricsConfigs.PhDrainConfig,
    MetricsConfigs.YieldConfig,
    MetricsConfigs.RipeningTimeConfig,
    MetricsConfigs.SettingSpeedConfig,
    MetricsConfigs.FloweringSpeedConfig,
    MetricsConfigs.FlowersTrussConfig,
    MetricsConfigs.FruitTrussConfig,
    MetricsConfigs.FruitStemConfig,
    MetricsConfigs.FruitLoadConfig,
    MetricsConfigs.SetFruitPerM2Config,
    MetricsConfigs.SetFruitPerPlantConfig,
    MetricsConfigs.HarvestFruitConfig,
    MetricsConfigs.HarvestTrussConfig,
    MetricsConfigs.FruitWeightConfig,
    MetricsConfigs.PlantGrowthConfig,
    MetricsConfigs.FloweringHeightConfig,
    MetricsConfigs.InternodeLengthConfig,
    MetricsConfigs.StemDiameterConfig,
    MetricsConfigs.LeafCountConfig,
    MetricsConfigs.LeafLengthConfig,
    MetricsConfigs.LeafWidthConfig,
    MetricsConfigs.HeadDensityConfig,
    MetricsConfigs.GasConfig,
    MetricsConfigs.ElectricityConfig,
];
