import { inject, Injectable } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { EMPTY, filter, map, Observable } from 'rxjs';

import { distinct } from '@priva/utilities/predicate';
import {
    PrivaVariableDataService,
    PrivaVariableRequestContainer,
    PrivaVariableResponse,
    ServiceType,
} from '@priva/variables';

import { roundToMetricDecimals } from '@app/metrics';
import { convertWeekToDate, getAllIsoWeeksBetween } from '@app/utilities';

import { KPIS_WEEK_REQUEST_TYPE, KpisRequest, KpiWeek } from './kpis.api.model';
import { KpisApiService } from './kpis.api.service';

dayjs.extend(isBetween);

export interface KpiChartValues {
    value: { x: Dayjs; y: number | null };
    target: { x: Dayjs; y: number | null };
    bandMin: { x: Dayjs; y: number | null };
    bandMax: { x: Dayjs; y: number | null };
}

@Injectable({
    providedIn: 'root',
})
export class KpisWeekService implements PrivaVariableDataService {
    private readonly kpisApiService = inject(KpisApiService);
    public readonly source = KPIS_WEEK_REQUEST_TYPE;
    public readonly type = ServiceType.Service;

    public getData(
        requestData: PrivaVariableRequestContainer<KpisRequest>[],
        _offsetInMinutes: number,
        start: Date,
        end: Date,
    ): Observable<PrivaVariableResponse> {
        if (!requestData?.length) {
            return EMPTY;
        }
        const dayjsStart = dayjs(start);
        const dayjsEnd = dayjs(end);

        // 1) Collect all KPI IDs and ensure there's exactly one distinct ID
        const requests = requestData.map((requestContainer) => requestContainer.request);
        const kpiIdsForEachRequest = requests.flatMap(({ kpiId }) => kpiId).filter(distinct());
        if (kpiIdsForEachRequest.length !== 1) {
            return EMPTY;
        }

        const kpiIds = [kpiIdsForEachRequest[0]];
        const cropId = requestData[0].request.cropId;

        // 2) Fetch and transform data
        return this.kpisApiService.getKpisWeek(dayjsStart, dayjsEnd, kpiIds, cropId).pipe(
            filter(({ kpiValues }) => !!kpiValues?.length),
            map((response): PrivaVariableResponse => {
                // Collect all kpi week values into one flat array
                const values: KpiChartValues[] = response.kpiValues
                    .map((kpi) => this.getTargetValues(kpi))
                    .flat();

                // Generate all weeks from start to end (as dayjs objects)
                const allWeeks = getAllIsoWeeksBetween(dayjsStart, dayjsEnd);

                // Fill in missing weeks
                const filledValues = values.length ? this.fillMissingWeeks(allWeeks, values) : values;

                // Create the PrivaVariableResponse
                return this.createKpi(requestData, filledValues);
            }),
        );
    }

    public getTargetValues(kpi: KpiWeek): KpiChartValues[] {
        if (!kpi.values || kpi.values.length === 0) {
            // Return an empty array if values are empty
            return [];
        }

        return kpi.values.map((value) => {
            const date = convertWeekToDate(value.week);
            return {
                value: {
                    x: date,
                    y: roundToMetricDecimals(value.value, kpi.kpiId),
                },
                target: {
                    x: date,
                    y: value.target ? roundToMetricDecimals(value.target.value, kpi.kpiId) : null,
                },
                bandMin: {
                    x: date,
                    y: value.target ? roundToMetricDecimals(value.target.bandMinValue, kpi.kpiId) : null,
                },
                bandMax: {
                    x: date,
                    y: value.target ? roundToMetricDecimals(value.target.bandMaxValue, kpi.kpiId) : null,
                },
            };
        });
    }

    public createKpi(
        requestData: PrivaVariableRequestContainer<KpisRequest>[],
        values: KpiChartValues[],
    ): PrivaVariableResponse {
        const responseData = requestData.map((request) => {
            const key = request.variableId.split('_')[0];
            const valuesArray = values.map((value) => value[key]);
            return {
                props: request,
                values: valuesArray,
            };
        });

        return {
            source: this.source,
            data: [...responseData],
        };
    }

    private fillMissingWeeks(allWeeks: Dayjs[], existingData: KpiChartValues[]) {
        const filled: typeof existingData = [...existingData];

        allWeeks.forEach((week) => {
            // Check if this week already exists by comparing the 'x' date
            const found = filled.some((entry) => entry.value.x.isSame(week, 'week'));
            if (!found) {
                filled.push({
                    value: { x: week, y: null },
                    target: { x: week, y: null },
                    bandMin: { x: week, y: null },
                    bandMax: { x: week, y: null },
                });
            }
        });

        // Sort by date
        return filled.sort((a, b) => a.value.x.diff(b.value.x));
    }
}
